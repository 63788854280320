<template>
  <div class="courseware">
    <div class="search my-3"></div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="课件管理" name="courseware" v-if="!isCustomer">
        <avue-crud
          ref="crud"
          :data="wareData"
          :option="courseOption"
          :page.sync="page"
          @on-load="changPage"
          :table-loading="loading"
          @search-change="search"
          @selection-change="selectionChange"
          @row-del="remove"
        >
          <template slot="menuLeft">
            <el-button
              v-if="[0, 1, 2, 3, 4, 5, 6, 7].includes(user.role)"
              type="primary"
              size="small"
              icon="el-icon-download"
              @click="batchDownload"
              >批量下载
            </el-button>
          </template>

          <template #menu-left="{ size }">
            <el-button type="primary" :size="size">自定义按钮</el-button>
          </template>

          <template slot="filename" slot-scope="scope">
            <div class="d-flex align-items-center">
              <el-button class="mx-2" type="text" icon="el-icon-document">
              </el-button>

              <el-tooltip
                class="item"
                effect="dark"
                :content="`${scope.row.filename}`"
                placement="top-start"
              >
                <span
                  class="text-truncate cursor-pointer"
                  @click="previewFile(scope.row)"
                >
                  {{ scope.row.filename }}</span
                >
              </el-tooltip>

              <el-tooltip
                class="item"
                effect="dark"
                :content="`下载文件 ${scope.row.filename}`"
                placement="top-start"
                style="width: 50px"
              >
              <!-- v-if="[0, 5].includes(user.role)" -->

                <el-button
                  type="text"
                  icon="el-icon-download"
                  @click="dwonloadItem(scope.row)"
                >
                </el-button>
              </el-tooltip>
            </div>
          </template>

          <template slot="uploadTime" slot-scope="scope">
            <div>
              {{ formatDateY(scope.row.uploadTime) }}
            </div>
          </template>
          <template slot="user" slot-scope="scope">
            <div>
              {{ scope.row.user.realname }}
            </div>
          </template>
        </avue-crud>
      </el-tab-pane>
      <el-tab-pane label="课程大纲" name="outline">
        <FileList type="outline" />
      </el-tab-pane>
      <el-tab-pane label="公司产品" name="company">
        <FileList type="company" />
      </el-tab-pane>
      <el-tab-pane label="头像证书" name="certificate">
        <FileList type="certificate" />
      </el-tab-pane>
      <el-tab-pane label="视频片段" name="video">
        <FileList type="video" />
      </el-tab-pane>
       
      <el-tab-pane label="营销工具" name="marketing">
        <FileList type="marketing" />
      </el-tab-pane>
      <el-tab-pane label="业务案例" name="business_case">
        <FileList type="business_case" />
      </el-tab-pane>
      <el-tab-pane label="合同模板" name="contract">
        <FileList type="contract" />
      </el-tab-pane>
      <el-tab-pane label="授课记录" name="lecture">
        <FileList type="lecture" />
      </el-tab-pane>
      <el-tab-pane label="流程制度" name="process">
        <FileList type="process" />
      </el-tab-pane>
   
    </el-tabs>
  </div>
</template>

<script>
import { formatDate } from "../../filters";
import { formatDateY } from "../../filters";
import { ossClient } from "../../plugins/upload";
import { Docxer } from "../../plugins/docx";
import FileList from "../../components/fileList.vue";

export default {
  data() {
    return {
      loading: false,
      activeName: "courseware",
      page: {
        total: 0,
        // currentPage:1,
        pageSize: 50,
        pageSizes: [10, 20, 30, 40, 50],
      },
      wareData: [],

      dataQuery: {
        name: "",
        filename: "",
        limit: 50,
        page: 1,
      },
      select_list: [],
      courseOption: {
        size: "mini",
        align: "center",
        border: true,
        selection: true,
        index: true,
        indexLabel: "序号",
        searchMenuSpan: 4,
        header: true,
        dialogClickModal: false,
        emptyBtn: false,
        border: true,
        menuWidth: 160,
        translate: false,
        editBtn: false,
        addBtn: false,
        refreshBtn: false,
        searchShowBtn: false,
        columnBtn: false,
        copyBtn: false,
        column: [
          {
            label: "讲师姓名",
            prop: "name",
            border: true,
            type: "select",
            props: {
              label: "name",
              value: "_id",
            },
            span: 24,
            slot: true,
            dicUrl: "teachers/option",
            searchFilterable: true,
            search: true,
            width: 100,
          },
          // {
          //   label: "合作机构",
          //   prop: "customer_name",
          //   border: true,
          //   span: 24,
          //   slot: true,
          // },

          {
            label: "课件名",
            prop: "filename",
            border: true,
            span: 24,
            slot: true,
            search: true,
            whidth: 800,
          },
          {
            label: "经纪人",
            prop: "user",
            border: true,
            type: "select",
            props: {
              label: "realname",
              value: "_id",
            },
            span: 24,
            slot: true,
            dicUrl: "users/leader/option",
            searchFilterable: true,
            search: true,
            width: 100,
          },

          {
            label: "上传时间",
            prop: "uploadTime",
            border: true,
            span: 24,
            slot: true,
            width: 120,
          },
          // {
          //   label: "状态",
          //   prop: "uploadTime",
          //   border: true,
          //   span: 24,
          //   slot: true,
          // },
        ],
      },
    };
  },
  methods: {
    formatDate,
    formatDateY,
    handleClick(tab, event) {
      console.log(this.activeName);
    },

    async dwonloadItem(item) {
      if (!item.url) {
        return false;
      }
      this.loading = true;
      await ossClient.donwloadSingleFile(item.url, item.filename);
      this.loading = false;
      this.addBehavior({
        user: this.user._id,
        action: "download",
        data: {
          key: "courseware",
          files:[{
            url:item.url,
            filename:item.filename
          }]
        },
      });
    },
    async remove(row) {
      await this.$confirm("是否确认删除此课件？");
      await this.$http.put(`courseware/remove_url`, {
        schedule_id: row._id,
        course_ware: row.course_ware,
      });

      this.$message.success("删除成功");
      this.fetchData();
    },
    async changPage({ pageSize, currentPage }) {
      this.dataQuery.page = currentPage;
      this.dataQuery.limit = pageSize;
      this.fetchData();
    },
    selectionChange(vals) {
      this.select_list = vals;
    },

    async previewFile(file) {
      const url = file.url;
      const ext = url.split(".").pop();

      const isDoc = ["doc", "docx", "ppt", "pptx", "xlsx"].includes(ext);

      const isPdf = ["pdf"].includes(ext);

      const isImage = ["png", "jpeg", "jpg"].includes(ext);

      if (isImage) {
        return this.$ImagePreview([file], 0, {
          closeOnClickModal: true,
          interval: 1000,
        });
      }

      if (isPdf) {
        return window.open(url);
      }

      if (!isDoc && !isImage) {
        return this.$message.error("文件类型暂不支持预览");
      }

      if (isDoc) {
        return Docxer.viewDoc(url);
      }
    },

    async batchDownload() {
      let urls = this.select_list.map((v) => {
        return {
          filename: v.filename,
          url: v.url,
        };
      });

      urls = urls.reduce((arr, cur) => {
        const has = arr.find((v) => v.filename === cur.filename);
        return (arr = arr.concat(has ? [] : [cur]));
      }, []);

      if (!urls.length) {
        return this.$message.error("请选择课件");
      }

      console.time("download");

      // const loading = this.$loading({
      //   lock: true,
      //   text: "文件下载中... 0%",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });

      await ossClient.batchDownload(
        urls,
        () => {
          this.select_list = [];
          if (this.$refs.crud) {
            this.$refs.crud.toggleSelection();
          }
          this.$message.success("下载成功");
          this.addBehavior({
            user: this.user._id,
            action: "download",
            data: {
              key: "courseware",
              files:urls,
            },
          });
        },
        () => {
          // loading.text = "压缩中";
          this.$message.error("下载失败,请重试");
        }
      );

      console.timeEnd("download");

      // loading.close();
    },

    search(val, done) {
      this.dataQuery.name = val.name || "";
      this.dataQuery.filename = val.filename || "";
      this.dataQuery.user = val.user || "";
      done();
      this.fetchData();
    },

    // 获取课件管理
    async fetchData() {
      const { data } = await this.$http.get("courseware/list", {
        params: {
          ...this.dataQuery,
        },
      });
      this.page.total = data.total;
      this.wareData = data.data;
    },
  },
  components: {
    FileList,
  },

  created() {
    if (this.isCustomer) {
      this.activeName = "outline";
    }
    this.fetchData();
  },
};
</script>

<style>
.courseware .text-truncate {
  color: #409eff;
}
</style>
