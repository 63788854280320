import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import * as zip from "jszip";
import { Message } from 'element-ui';
function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

class DocxHelper {
  constructor() {
    this.templateUrl =
      "https://womeng-admin-file.oss-cn-beijing.aliyuncs.com/womeng/3feffc0a1af80b219d3afc2871be46ce.docx";
    this.templatecontent = "";
    this.docx = null;
  }

  initTemplate() {
    return new Promise((res, rej) => {
      loadFile(this.templateUrl, (error, content) => {
        if (error) {
          rej(error);
        }
        const zip = new PizZip(content);
        this.docx = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
        res(zip);
      });
    });
  }

  async getSingleWord(data) {
    this.docx.setData(data);
    this.docx.render();
    const generatedDocument = this.docx.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    return generatedDocument;
  }

  async downloadSignle(data, filename) {
    await this.initTemplate();
    const blob = await this.getSingleWord(data);
    saveAs(blob, filename);
  }

  async batchDownload(result, progress) {
    const jsZip = new zip();
    let doing = 0;
    const length=Object.keys(result).length;
    for (let item of result) {
    await this.initTemplate();
      const blob = await this.getSingleWord(item.data);
      jsZip.file(item.filename, blob, { binary: true });
      doing += 1;

      progress &&
        progress({
          total: length,
          now: doing,
          val: Math.floor((doing / length) * 100),
        });
    }
    const blob = await jsZip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 1,
      },
    });
    saveAs(blob, `${Date.now()}.zip`);
  }


  async  viewDoc(url){
    if(!url){
      return Message.error("链接不能为空")
    }
   
    const viewUrl=`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`
    window.open(viewUrl)
  }
}

export const Docxer = new DocxHelper();
