import streamSaver from "streamsaver";
//引入我们上面提到过的文件
import "./zip-stream.js";
import * as dayjs from "dayjs";

//修改插件中的默认值改为自己本地（发布到线上记得改为线上地址）



let env=process.env.NODE_ENV;
let mitm="";
if(env=="development"){
   mitm="http://localhost:8080/mitm.html"
}else {
  mitm="https://womeng.worthdaily.com/mitm.html"
}
streamSaver.mitm = mitm;

let data = [
  {
    teacher: "艾静",
    photos: [
      {
        sources: [
          "http://womeng-admin-file.oss-cn-beijing.aliyuncs.com/womeng/6a5dddf022e092e0aaae13c85bbf68d0.jpg",
          "http://womeng-admin-file.oss-cn-beijing.aliyuncs.com/womeng/412bded87f179a16f21478614f8b9203.png",
        ],
        terminal_name: "农商行",
        times: ["2021-09-10T16:00:00.000Z", "2021-09-11T16:00:00.000Z"],
      },
      {
        sources: [
          "http://womeng-admin-file.oss-cn-beijing.aliyuncs.com/womeng/945c72ba417e0c2f45386efa06514853.png",
        ],
        terminal_name: "汉口银行",
        times: ["2022-05-17T16:00:00.000Z", "2022-05-18T16:00:00.000Z"],
      },
    ],
  },
];

export class InfoSaver {
  parseList(data) {
    let list = data.reduce((arr, cur) => {
      const items = cur.photos.reduce((ss, c) => {
        const sl = c.sources.map((sv) => {
          const filename = sv.split("/").pop();
          return {
            url: sv,
            path: `${cur.teacher}/${c.terminal_name}/${filename}`,
            // path: `${filename}`,
          };
        });
        return (ss = ss.concat(sl));
      }, []);
      return (arr = arr.concat(items));
    }, []);

    return list;
  }
  download(data, success, error) {
    const files = this.parseList(data);
    let urlArr = files.values(); //迭代对象

    let filename=`讲师图片${dayjs().format("YYYY-MM-DD")}.zip`
   
    let fileStream = streamSaver.createWriteStream(filename);
    let readableZipStream = new window.ZIP({
      pull(ctrl) {
        const it = urlArr.next();
        // console.log(it);
        if (it.done) {
          //迭代终止
          ctrl.close();
        } else {
          let { path, url } = it.value;
          if(!url.includes("https")){
            url=url.replace("http","https")
          }
          console.log({
            path,
            url,
          });
          return fetch(url).then((res) => {
            // console.log(res.body, path);
            ctrl.enqueue({
              name: path,
              stream: () => res.body,
            });
          });
        }
      },
    }); 

    if (window.WritableStream && readableZipStream.pipeTo) {
      return readableZipStream
        .pipeTo(fileStream)
        .then(() => {
          success && success();
        })
        .catch((e) => {
          console.log(e)
          error && error(e);
        });
    }
  }

  downloadCourses(urls,success,error,filename=null){
    let urlArr = urls.values(); //迭代对象
    let fileStream = streamSaver.createWriteStream(`${filename||Date.now()}.zip`);
    let readableZipStream = new window.ZIP({
      pull(ctrl) {
        const it = urlArr.next();
        if (it.done) {
          //迭代终止
          ctrl.close();
        } else {
          let { url,filename } = it.value;
          if(!url.includes("https")){
            url=url.replace("http","https")
          }
          return fetch(url).then((res) => {
            ctrl.enqueue({
              name: filename,
              stream: () => res.body,
            });
          });
        }
      },
    }); 

    if (window.WritableStream && readableZipStream.pipeTo) {
      return readableZipStream
        .pipeTo(fileStream)
        .then(() => {
          success && success();
        })
        .catch((e) => {
        
          error && error(e);
        });
    }
  }

  donwloadOutlines(urls,success,error,filename=null){

  }

  async getOss(url, filepath) {
    const filename = url.split("/").pop();
    const fileStream = streamSaver.createWriteStream(filename);
   
    console.log(url)
    const res = await fetch(url);
    const stream = () => res.body;
  }
}

export const saver = new InfoSaver();
