<template>
  <div class="finace px-2">
    <div class="d-flex justify-content-end">
      <div
        class="pr-4 pt-3 d-flex align-items-center"
        v-if="user && user.role == 8"
      >
        <div class="pr-3">选择小组</div>
        <el-select
          style="width:350px"
          clearable
          multiple
          v-model="query.where.groups"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="pr-4 pt-3 d-flex align-items-center">
        <div class="pr-3">选择单位</div>
        <el-select
          style="width:350px"
          clearable
          multiple
          v-model="query.where.customers"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customer_options"
            :key="item._id"
            :label="item.name"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="pr-4 pt-3 d-flex align-items-center">
        <div class="pr-3">选择讲师</div>
        <el-select
          style="width:350px"
          clearable
          multiple
          v-model="query.where.teachers"
          placeholder="请选择"
        >
          <el-option
            v-for="item in teacher_options"
            :key="item._id"
            :label="item.name"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="mr-3 pt-3 d-flex align-items-center">
        <div class="pr-3" style="width:110px">数据月份</div>
        <!-- <el-date-picker
          :clearable="false"
          v-model="query.where.time"
          type="month"
          placeholder="选择年月"
        >
        </el-date-picker> -->

        <el-date-picker
          :clearable="false"
          v-model="times"
          type="monthrange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
    </div>
    <el-row :gutter="24" class="m-auto">
      <el-col :span="4" class="py-2">
        <el-card class="card mt-2">
          <div
            class="d-flex justify-content-between align-items-center"
            @click="to(item)"
          >
            <div>
              <el-image
                src="http://womeng-admin-file.oss-cn-beijing.aliyuncs.com/womeng/9bf35e299adf24873c9302355c74601f.png"
                class="img-size"
              ></el-image>
            </div>
            <div class="cursor-pointer">
              <div class="option-title">已回款</div>
              <div style="width: 140px">
                <span class="option-count pr-1">{{
                  (countData ? countData.received / 10000 : 0).toFixed(2)
                }}</span>
                <span class="fs-10">万元</span>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4" class="py-2">
        <el-card class="card mt-2">
          <div
            class="d-flex justify-content-between align-items-center"
            @click="to(item)"
          >
            <div>
              <el-image
                src="http://womeng-admin-file.oss-cn-beijing.aliyuncs.com/womeng/0b7f2a0d6e306b661a24d28378f1e349.png"
                class="img-size"
              ></el-image>
            </div>
            <div class="cursor-pointer">
              <div class="option-title">未回款</div>
              <div style="width: 140px">
                <span class="option-count  pr-1">{{
                  (countData ? countData.remain / 10000 : 0).toFixed(2)
                }}</span>
                <span class="fs-10">万元</span>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4" class="py-2">
        <el-card class="card mt-2">
          <div
            class="d-flex justify-content-between align-items-center"
            @click="to(item)"
          >
            <div>
              <el-image
                src="http://womeng-admin-file.oss-cn-beijing.aliyuncs.com/womeng/b977fafef967ef679d57e8ac4736e1ac.png"
                class="img-size"
              ></el-image>
            </div>
            <div class="cursor-pointer">
              <div class="option-title">已开票未回款</div>
              <div style="width: 140px">
                <span class="option-count pr-1">{{
                  (countData ? countData.billRemain / 10000 : 0).toFixed(2)
                }}</span>
                <span class="fs-10">万元</span>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4" class="py-2">
        <el-card class="card mt-2">
          <div
            class="d-flex justify-content-between align-items-center"
            @click="to(item)"
          >
            <div>
              <el-image
                src="http://womeng-admin-file.oss-cn-beijing.aliyuncs.com/womeng/1343ca9cfb6494086d5c29528549d9d4.png"
                class="img-size"
              ></el-image>
            </div>
            <div class="cursor-pointer">
              <div class="option-title">未开票未回款</div>
              <div style="width: 140px">
                <span class="option-count pr-1">{{
                  (countData ? countData.noBillRemain / 10000 : 0).toFixed(2)
                }}</span>
                <span class="fs-10">万元</span>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4" class="py-2">
        <el-card class="card mt-2">
          <div
            class="d-flex justify-content-between align-items-center"
            @click="to(item)"
          >
            <div>
              <el-image
                src="http://womeng-admin-file.oss-cn-beijing.aliyuncs.com/womeng/bd43c76e127da734044fd2c5619c6873.png"
                class="img-size"
              ></el-image>
            </div>
            <div class="cursor-pointer">
              <div class="option-title">三个月以上未回款</div>
              <div style="width: 140px">
                <span class="option-count pr-1">{{
                  (countData ? countData.subremain / 10000 : 0).toFixed(2)
                }}</span>
                <span class="fs-10">万元</span>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <div v-if="user.role !== 1">
      <table style="width: 98%" class="group-table my-3" align="center">
        <thead>
          <tr align="left" border="1">
            <th class="pl-3">经纪人部门</th>
            <th>本年销售额</th>
            <th>已回款(万元)</th>
            <th>未回款(万元)</th>
            <th>已开票未回款(万元)</th>
            <th>未开票未回款(万元)</th>
            <th>3个月以上未回款(万元)</th>
          </tr>
        </thead>
        <tbody
          class="border-bottom border-left border-right"
          v-for="(item, index) in this.groups"
          :key="index"
        >
          <tr class="font-weight-bold fs-16 border-bottom">
            <td align="left" class="pl-3 py-2 pb-2">
              <span>{{ item.group_name }}</span>
              <span
                @click="open(item)"
                class="ml-2 import-ex iconfont icon-icon-GIS_shouqi-"
                v-if="item.isSubShow"
              ></span>
              <span
                @click="open(item)"
                v-else
                class="ml-2 import-ex iconfont icon-icon-12"
              ></span>
            </td>
            <td
              align="left"
              class="pb-2 cursor-pointer font-weight-bold"
              @click="groupSearch(item.group_name, 'yearTotal', true)"
            >
              <el-link class="font-weight-bold">
                {{ groupCollection(_.cloneDeep(item), "yearTotal") }}
              </el-link>
            </td>
            <td
              align="left"
              class="pb-2 cursor-pointer font-weight-bold"
              @click="groupSearch(item.group_name, 'received', true)"
            >
              <el-link class="font-weight-bold">
                {{ groupCollection(_.cloneDeep(item), "received") }}
              </el-link>
            </td>
            <td
              align="left"
              class="pb-2 cursor-pointer"
              @click="groupSearch(item.group_name, 'remain', true)"
            >
              <el-link class="font-weight-bold">
                {{ groupCollection(_.cloneDeep(item), "remain") }}
              </el-link>
            </td>
            <td
              align="left"
              class="pb-2 cursor-pointer"
              @click="groupSearch(item.group_name, 'billRemain', true)"
            >
              <el-link class="font-weight-bold">
                {{ groupCollection(_.cloneDeep(item), "billRemain") }}
              </el-link>
            </td>
            <td
              align="left"
              class="pb-2 cursor-pointer"
              @click="groupSearch(item.group_name, 'noBillRemain', true)"
            >
              <el-link class="font-weight-bold">
                {{ groupCollection(_.cloneDeep(item), "noBillRemain") }}
              </el-link>
            </td>
            <td
              align="left"
              class="pb-2 cursor-pointer"
              @click="groupSearch(item.group_name, 'one_month', true)"
            >
              <el-link class="font-weight-bold">
                {{ groupCollection(_.cloneDeep(item), "subremain") }}
              </el-link>
            </td>
            <!-- <td style="width:80px" @click="open(index)">></td> -->
          </tr>

          <tr v-show="item.isSubShow&&item.goupId">
            <td align="left" class="pb-2 pl-4">{{ item.realname }}（组长）</td>
            <td
              @click="groupSearch(item.realname, 'yearTotal', false)"
              align="left"
              class="pb-2 cursor-pointer"
            >
              <el-link>
                {{ ((item.yearTotal || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
            <td
              align="left"
              class="pb-2 cursor-pointer"
              @click="groupSearch(item.realname, 'yearTotal', false)"
            >
              <el-link>
                {{ ((item.received || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
            <td
              align="left"
              class="pb-2 cursor-pointer"
              @click="groupSearch(item.realname, 'remain', false)"
            >
              <el-link>
                {{ ((item.remain || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
            <td
              @click="groupSearch(item.realname, 'billRemain', false)"
              align="left"
              class="pb-2 cursor-pointer"
            >
              <el-link>
                {{ ((item.billRemain || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
            <td
              @click="groupSearch(item.realname, 'noBillRemain', false)"
              align="left"
              class="pb-2 cursor-pointer"
            >
              <el-link>
                {{ ((item.noBillRemain || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
            <td
              @click="groupSearch(item.realname, 'one_month', false)"
              align="left"
              class="pb-2 cursor-pointer"
            >
              <el-link>
                {{ ((item.subremain || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
          </tr>
          <tr
            v-for="user in item.userInfo"
            :key="user._id"
            v-show="item.isSubShow"
          >
            <td align="left" class="pb-2 pl-4">{{ user.realname }}</td>
            <td
              @click="groupSearch(user.realname, 'yearTotal', false)"
              align="left"
              class="pb-2 cursor-pointer"
            >
              <el-link>
                {{ ((user.yearTotal || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
            <td
              align="left"
              class="pb-2 cursor-pointer"
              @click="groupSearch(user.realname, 'received', false)"
            >
              <el-link>
                {{ ((user.received || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
            <td
              @click="groupSearch(user.realname, 'remain', false)"
              align="left"
              class="pb-2 cursor-pointer"
            >
              <el-link>
                {{ ((user.remain || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
            <td
              @click="groupSearch(user.realname, 'billRemain', false)"
              align="left"
              class="pb-2 cursor-pointer"
            >
              <el-link>
                {{ ((user.billRemain || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
            <td
              @click="groupSearch(user.realname, 'noBillRemain', false)"
              align="left"
              class="pb-2 cursor-pointer"
            >
              <el-link>
                {{ ((user.noBillRemain || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
            <td
              @click="groupSearch(user.realname, 'one_month', false)"
              align="left"
              class="pb-2 cursor-pointer"
            >
              <el-link>
                {{ ((user.subremain || 0) / 10000).toFixed(2) }}
              </el-link>
            </td>
          </tr>
        </tbody>
        <!-- <tbody class="pt-3">
          <tr class="mt-3 font-weight-bold">
            <td class="pl-5">总计:</td>
            <td align="center">{{ groupTotal.received }}</td>
            <td align="center">{{ groupTotal.remain }}</td>
            <td align="center">{{ groupTotal.billRemain }}</td>
            <td align="center">{{ groupTotal.noBillRemain }}</td>
            <td align="center">{{ groupTotal.subremain }}</td>
          </tr>
        </tbody> -->
      </table>
    </div>

    <!-- 机构金额排行数据 -->

    <div
      class="d-flex  customer-detail-target justify-content-between align-items-center mx-2 my-2"
    >
      <div class="d-flex mt-3">
        <el-select
          style="width:200px"
          placeholder="选择回款状态"
          v-model="query.where.type"
        >
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>

        <el-select
          style="width:320px"
          filterable
          clearable
          class="mx-3"
          placeholder="客户名称"
          v-model="customer_name"
        >
          <el-option
            v-for="item in customerOption"
            :key="item._id"
            :label="item.name"
            :value="item._id"
          />
        </el-select>

        <div style="width:200px" v-if="user.role !== 1">
          <!-- <el-input
            clearable
            v-model="user_name"
            placeholder="请输入负责人名称"
            @change="searchUser"
          ></el-input> -->

          <el-select
            filterable
            clearable
            style="width:200px"
            placeholder="选择负责人"
            v-model="user_name"
          >
            <el-option
              v-for="item in users"
              :key="item.realname"
              :label="item.realname"
              :value="item.realname"
            />
          </el-select>
        </div>

        <el-select
            filterable
            clearable
            style="width:200px"
            placeholder="选择排序方式"
            v-model="sort_key"
            class="mx-3"
          >
            <el-option
              v-for="item in sort_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>

        <!-- <el-form-item label="活动名称">
          <el-input></el-input>
        </el-form-item> -->

        <el-button
          class="ml-2"
          type="primary"
          @click="search"
          icon="el-icon-search"
          >查询</el-button
        >
      </div>
      <div class="ml-3">
        <el-button size="small" type="success" plain @click="exportDetailed"
          >导出明细</el-button
        >
      </div>
    </div>
    <div class="pl-2" v-if="type !== 'received'">
      <span class="fs-10">{{ identity }}总额：</span
      ><span>
        <span class="fs-12">{{
          (remainTotal ? remainTotal / 10000 : 0).toFixed(2)
        }}</span>
        <span class="fs-10 pl-1">万元</span>
      </span>
    </div>
    <avue-crud
      :data="data"
      :option="option"
      :page.sync="page"
      @on-load="changPage"
    >
      <template #times="{ row }">
        <el-tooltip
          effect="dark"
          :content="
            _.get(row, 'times', [])
              .map((v) => $filters.formatDateT(v))
              .join('，')
          "
          placement="top-start"
        >
          <!-- <div v-for="(item, i) in _.get(row, 'times', [])" :key="i">
                  {{ $filters.formatDateT(item) }}
                </div>-->

          <div class="cursor-pointer text-truncate1" style="max-width: 20vw">
            <!-- {{ scope.row.times && scope.row.times.join("<br>") | formatDateT
                  }}-->

            <div
              class="times"
              v-for="(item, i) in _.get(row, 'times', []).slice(0, 4)"
              :key="i"
            >
              <span :key="i">{{ $filters.formatDateT(item) }}</span>
              <!-- <br :key="`br-` + i" /> -->
            </div>
          </div>
        </el-tooltip>
      </template>

      <template slot="teacher" slot-scope="scope">
        <div>{{ _.get(scope.row, "teacher.name") }}</div>
      </template>

      <template slot="remain" slot-scope="scope">
        {{ scope.row.remain }}
      </template>

      <template slot="customer" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="_.get(scope.row, 'customer.name')"
          placement="top-start"
        >
          <div class="text-truncate cursor-pointer" style="max-width: 30vw">
            {{ _.get(scope.row, "customer.name") }}
          </div>
        </el-tooltip>
      </template>

      <template slot-scope="scope" slot="user">
        <div>{{ _.get(scope.row, "user.realname") }}</div>
      </template>

      <template slot-scope="scope" slot="estimated_time">
        <div>{{ scope.row.estimated_time | formatDate }}</div>
      </template>
      <template #payBack="{ row }">
        <div v-for="(item, index) in row.payBack" :key="index">
          <div>
            <div>日期：{{ $filters.formatDateT(item.date) }}</div>
            <div>金额：&nbsp; {{ item.money }}</div>
          </div>
        </div>
      </template>

      <template slot-scope="scope" slot="menu">
        <el-button
          v-if="scope.row.collection_dynamics && scope.row.collection_dynamics"
          icon="el-icon-edit"
          @click="edit(scope.row)"
          :size="scope.size"
          :type="scope.type"
          >编辑回款信息</el-button
        >
        <el-button
          v-else
          icon="el-icon-plus"
          @click="add(scope.row)"
          :size="scope.size"
          :type="scope.type"
          >新增回款信息</el-button
        >
      </template>
    </avue-crud>

    <div>
      <div class="font-weight-bold fs-16 pl-2">各机构未回款项</div>
      <avue-crud
        :data="customerData"
        :option="rankOptions"
        :page.sync="rankPage"
        @on-load="changPageCustomer"
      >
        <template #menu="{row,size}">
          <el-button
            type="text"
            icon="el-icon-edit"
            :size="size"
            @click="watchCustomer(row)"
            >查看详情</el-button
          >
        </template>

        <template slot="amount" slot-scope="scope">
          <div>
            {{ $filters.formatMoneyW(scope.row.amount) }}
          </div>
        </template>
        <template slot="receive_count" slot-scope="scope">
          <div>
            {{ $filters.formatMoneyW(scope.row.receive_count) }}
          </div>
        </template>
        <template slot="remain_count" slot-scope="scope">
          <div>
            {{ $filters.formatMoneyW(scope.row.remain_count) }}
          </div>
        </template>
      </avue-crud>
    </div>

    <el-dialog
      class="dialog"
      :close-on-click-modal="false"
      :title="optTitle"
      :visible.sync="dialogVisible"
      :before-close="beforeClose"
      ref="dialog"
      width="40%"
    >
      <avue-form
        v-if="dialogVisible"
        ref="form"
        :option="scheduleOption"
        v-model="obj"
        @submit="submit"
      >
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import * as dayjs from "dayjs";

const year = new Date().getFullYear();
export default {
  data() {
    return {
      isOpen: false,
      remainTotal: 0,
      customerOption: [],
      customerData: [],
      sort_key:"_id",
      sort_options:[
        {
          label:"单位",
          value:"customer.createdAt",
        },
        {
          label:"时间",
          value:"_id",
        },
        {
          label:"讲师",
          value:"teacher.createdAt",
        }
      ],
      rankOptions: {
        border: true,
        index: true,
        searchMenuSpan: 8,
        indexLabel: "序号",
        translate: false,
        addBtn: false,
        menu: true,
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        searchShowBtn: false,
        columnBtn: false,
        copyBtn: false,
        column: [
          {
            label: "机构名称",
            prop: "name",
            search: false,
            span: 8,
          },
          {
            label: "订单金额",
            prop: "amount",
            search: false,
            spanteaach: 8,
          },
          {
            label: "已回款",
            prop: "receive_count",
            search: false,
            span: 8,
          },
          {
            label: "未回款",
            prop: "remain_count",
            search: false,
            span: 8,
          },
        ],
      },
      rankPage: {
        total: 0,
        page: 1,
        limit: 10,
      },
      groups: [],
      dialogVisible: false,
      optTitle: "",
      obj: {},
      users: [],
      options: [],
      teacher_options: [],
      customer_options: [],
      times: [
        dayjs()
          .startOf("year")
          .toString(),
        dayjs().endOf("month").toString(),
      ],
      customer_name: null,
      user_name: null,
      query: {
        limit: 10,
        page: 1,
        groups: [],
        where: {
          time: [
            dayjs()
              .subtract(1, "year")
              .startOf("month")
              .toString(),
            dayjs().toString(),
          ],
          type: "remain",
          isExport: false,
          isGroup: true,
        },
      },
      type: "remain",
      status: [
        {
          label: "已回款",
          value: "received",
        },
        {
          label: "未回款",
          value: "remain",
        },
        {
          label: "已开票未回款",
          value: "billRemain",
        },
        {
          label: "未开票未回款",
          value: "noBillRemain",
        },
        {
          label: "三个月以上未回款",
          value: "one_month",
        },
        {
          label: "本年销售额",
          value: "yearTotal",
        },
        // {
        //   label: "二个月以上未回款",
        //   value: "subremain",
        // },
      ],

      page: {
        total: 0,
        current: 1,
        limit: 10,
      },
      finance_list: [],
      data: [],
      countData: {
        received: 0,
        remain: 0,
        noBillRemain: 0,
        billRemain: 0,
        subremain: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "本月",
            onClick(picker) {
              picker.$emit("pick", [new Date(), new Date()]);
            },
          },
          {
            text: "今年至今",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    // remainTotal2(){
    //   // console.log(data)
    //   const data = this.data
    //   let total
    //   data.forEach((v)=>{
    //      total += v.remain
    //   })
    //   return total || 0
    // },
    identity() {
      const roles = {
        billRemain: "已开票未回款",
        noBillRemain: "未开票未回款",
        received: "已回款",
        remain: "未回款",
        one_month: "三个月以上未回款",
      };
      return roles[this.type];
    },
    scheduleOption() {
      const where = this.type;
      return {
        size: "mini",
        align: "center",
        border: true,
        index: true,
        indexLabel: "序号",
        searchMenuSpan: 8,
        // menu: false,
        header: false,
        dialogClickModal: false,
        emptyBtn: false,
        border: true,
        translate: false,
        editBtn: false,
        column: [
          {
            label: "预计回款时间",
            prop: "estimated_time",
            type: "datetime",
            span: 20,
            slot: true,
            format: "yyyy-MM-dd hh:mm:ss",
            mock: {
              type: "datetime",
              format: "yyyy-MM-dd hh:mm:ss",
              now: true,
            },
            rules: [
              {
                required: true,
                message: "请选择预计回款时间",
                trigger: "blur",
              },
            ],
            hide: where == "received",
          },
          {
            label: "最近催款动态",
            prop: "collection_dynamics",
            type: "textarea",
            span: 24,
            minRows: 2,
            placeholder: "请填写最近催款动态",
            rules: [
              {
                required: true,
                message: "请填写最近催款动态",
                trigger: "blur",
              },
            ],
            hide: where == "received",
          },
        ],
      };
    },
    option() {
      const where = this.type;
      return {
        border: true,
        index: true,
        searchMenuSpan: 8,
        indexLabel: "序号",
        translate: false,
        addBtn: false,
        // menu: false,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "讲师",
            prop: "teacher",
            filterable: true,
            slot: true,
            span: 8,
            type: "select",
          },
          {
            label: "上课日期",
            prop: "times",
            type: "dates",
            format: "yyyy-MM-dd",
            formatter: (row, value) => value && value.join("-"),
            rules: [
              {
                required: true,
                message: "请选择上课时间",
                trigger: "blur",
              },
            ],
            slot: true,
            width: "106px",
          },
          {
            label: "客户名称",
            filterable: true,
            prop: "customer",
            // search: true,
            slot: true,
            props: {
              label: "name",
              value: "_id",
            },
            // span: 8,
            rules: [
              {
                required: true,
                message: "请选择客户名称",
                trigger: "blur",
              },
            ],
            width: "140px",
            // remote: true,
            type: "select",
            dicUrl: "customers/option",
          },
          {
            label: "课程名称",
            prop: "post",
            overHidden: true,
          },
          {
            label: "未回款金额",
            prop: "remain",
            // span: 8,
            // search: true,
            slot: true,
            // width: "120px",

            hide: where == "received",
          },

          {
            label: "订单总额",
            prop: "order_amount",
            type: "number",
            span: 8,
          },
          {
            label: "回款记录",
            prop: "payBack",
            // span: 8,
            // search: true,
            slot: true,
            // width: "120px",
            // showColumn: false,
            hide: where !== "received",
          },
          {
            label: "回款凭证",
            prop: "collection_voucher",
            dataType: "array",
            type: "upload",
            listType: "picture-img",
            action: "upload",
            hide: where !== "received",
            // span: 24,
          },

          {
            label: "预计回款时间",
            prop: "estimated_time",
            type: "date",
            span: 8,
            hide: where == "received",
          },
          {
            label: "最近催款动态",
            prop: "collection_dynamics",
            span: 8,
            hide: where == "received",
          },
          {
            label: "负责人",
            prop: "user",
            slot: true,
            display: false,
            // search: true,
          },
        ],
      };
    },
  },
  watch: {
    times: {
      async handler(value, oldvalue) {
      console.log(value)
        this.query.where.time = value;
        const loading = this.$loading({
          lock: true,
          text: "查询中，请稍后。请勿关闭刷新页面",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        await this.fetchFinanceData();
        await this.fetchGroupPayback();
        await this.fetchCustomers();
        this.fetchCustomerData();
        loading.close();
        this.$message.success(
          `已为您查询${dayjs(value[0]).format("YYYY-MM-DD") +
            "-" +
            dayjs(value[1]).format("YYYY-MM-DD")}数据`
        );
      },
    },
    "query.where.type": {
      handler() {
        this.customer_name = null;
        this.fetchCustomers();
        // this.fetchFinanceData();
      },
      deep: true,
    },
    "query.where.teachers": {
      handler() {
        this.fetchFinanceData();
        this.fetchGroupPayback();
        this.fetchCustomerData();
        // this.fetchFinanceData();
      },
      deep: true,
    },
    // "query.where.groups": {
    //   handler(value) {
    //     this.fetchFinanceData();
    //   },
    // },
  },
  methods: {
    open(item) {
      item.isSubShow = !item.isSubShow;
    },
    async groupSearch(name, type, group) {
      this.type = type;
      this.user_name = name;
      this.query.where.user = name;
      this.customer_name = null;
      this.query.where.customer = null;
      this.query.where.type = type;
      if (group) {
        this.query.where.isGroup = true;
      } else {
        this.query.where.isGroup = false;
      }

      if(type=="yearTotal"){
        console.log("今年")
      }
      const loading = this.$loading({
        lock: true,
        text: "查询中，请稍后。请勿关闭刷新页面",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      await this.fetchFinanceData();
      await this.fetchCustomers();
      loading.close();
      this.$message.success(`已为您查询${name}${this.identity}详情列表`);
    },

    searchUser(val) {
      this.user_name = val;
      this.fetchCustomers();
    },
    getReceived(name, type, group) {
      this.$message.success(`已为您查询${name}已回款详情列表`);
      this.user_name = name;
      this.query.where.user = name;
      this.query.where.type = type;
      if (group) {
        this.query.where.isGroup = true;
      } else {
        this.query.where.isGroup = false;
      }
      this.fetchFinanceData();
      // this.$router.push(
      //   `schedules/list?userId=${id}&payBack_type=received&new=true`
      // );
    },
    getRemain(name, type, group) {
      // console.log(id)
      this.$message.success(`已为您查询${name}未回款详情列表`);
      this.user_name = name;
      this.query.where.user = name;
      this.query.where.type = type;
      if (group) {
        this.query.where.isGroup = true;
      } else {
        this.query.where.isGroup = false;
      }
      this.fetchFinanceData();
    },

    getBillRemain(name, type) {
      this.$message.success(`已为您查询${name}已开票未回款详情列表`);
      this.user_name = name;
      this.query.where.user = name;
      this.query.where.type = type;
      if (group) {
        this.query.where.isGroup = true;
      } else {
        this.query.where.isGroup = false;
      }
      this.fetchFinanceData();
    },
    getnoBillRemain(name, type, group) {
      this.$message.success(`已为您查询${name}未开票未回款详情列表`);
      this.user_name = name;
      this.query.where.user = name;
      this.query.where.type = type;
      if (group) {
        this.query.where.isGroup = true;
      } else {
        this.query.where.isGroup = false;
      }
      this.fetchFinanceData();
    },
    groupCollection(item, value) {
      const total =
        item &&
        item.userInfo &&
        _.concat(
          item.userInfo?.map((v) => {
            return v[value] || 0;
          }),
          [(item.goupId?item[value]:0)  || 0]
        );

      return (eval(total.join("+")) / 10000).toFixed(2) || 0;
    },
    async fetchGroupPayback() {
      const res = await this.$http.get("home/groupPayback", {
        params: {
          query: JSON.stringify(this.query),
        },
      });
      this.groups = res.data;
    },
    // async dataSearch(where, done) {
    //   done();
    //   if (where.customer) {
    //     this.query.where.customer = where.customer;
    //   } else {
    //     this.query.where.customer = null;
    //   }
    //   if (where.user) {
    //     this.query.where.user = where.user;
    //   } else {
    //     this.query.where.user = null;
    //   }
    //   this.fetchFinanceData();
    // },
    async edit(row) {
      this.obj = row;
      this.dialogVisible = true;
      this.optTitle = "编辑回款信息";
    },
    async submit(obj, done) {
      this.$clear(this.obj);
      if (this.obj.billing_company == "") {
        this.obj.billing_company = null;
      }
      if (this.obj.customer == "") {
        this.obj.customer = null;
      }
      await this.$http.put(`schedules/${this.obj._id}`, obj);
      this.$message.success("提交成功");
      this.fetchFinanceData();
      done();
    },
    beforeClose() {
      this.dialogVisible = false;
      this.obj = {};
    },
    async add(row) {
      this.obj = row;
      this.dialogVisible = true;
      this.optTitle = "新增回款信息";
    },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.changPage2(currentPage, pageSize);
    },
    async changPageCustomer({ pageSize, currentPage }) {
      this.rankPage.page = currentPage;
      this.rankPage.limit = pageSize;
      this.fetchCustomerData();
    },
    async search() {
      const groups = this.options.map((v) => {
        return v.label;
      });

      if (groups.includes(this.user_name)) {
        this.query.where.isGroup = true;
      } else {
        this.query.where.isGroup = false;
      }

      if (this.query.where.type) {
        this.type = this.query.where.type;
      }
      if (this.customer_name) {
        this.query.where.customer = this.customer_name;
      } else {
        this.query.where.customer = null;
      }
      if (this.user_name) {
        this.query.where.user = this.user_name;
      } else {
        this.query.where.user = null;
      }
      this.fetchFinanceData();
      this.fetchCustomers();
    },
    async exportDetailed() {
      const loading = this.$loading({
        lock: true,
        text: "导出中，请稍后。请勿关闭刷新页面",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.query.where.isExport = true;
      const res = await this.$http.post(
        "home/finance/list",
        {
          params: {
            query: JSON.stringify({
              ...this.query,
              sort_key:this.sort_key
            }),
          },
        },
        {
          responseType: "arraybuffer",
        }
      );
      const link = document.createElement("a");
      let blob = new Blob([res.data], { type: "application/x-xls" });
      link.href = URL.createObjectURL(blob);
      const title = `${this.identity}明细-${dayjs(
        this.query.where.time[0]
      ).format("YYYY-MM-DD") +
        "-" +
        dayjs(this.query.where.time[1]).format("YYYY-MM-DD")}`;
      link.download = `${title}.xlsx`;
      link.click();
      document.body.appendChild(link);
      loading.close();
      this.$message.success("导出成功");
      this.query.where.isExport = false;
    },
    async fetchGroups() {
      const res = await this.$http.get(`users`, {
        params: {
          query: {
            limit: 0,
            where: {
              role: 5,
            },
          },
        },
      });
      this.options = res.data.data.map((v) => {
        return {
          value: v._id,
          label: v.group_name,
        };
      });
    },
    async fetchOptions() {
      const fetchTeachers = this.$http.get("teachers/option");
      const fetchCustomers = this.$http.get("customers/option");

      const [tes, ces] = await Promise.all([fetchTeachers, fetchCustomers]);

      this.teacher_options = tes.data;
      this.customer_options = ces.data;
    },
    async fetchCustomers() {
      const groups = this.options.map((v) => {
        return v.label;
      });
      // console.log(this.user_name, "fetch ");
      if (groups.includes(this.user_name)) {
        this.query.where.isGroup = true;
      } else {
        this.query.where.isGroup = false;
      }
      const res = await this.$http.get("customers/options", {
        params: {
          status: this.query.where.type,
          isGroup: this.query.where.isGroup,
          times: this.times,
          user_name: this.user_name,
        },
      });
      this.customerOption = res.data;
    },
    watchCustomer(row) {
      this.customer_name = row._id;
      this.search();
      this.scrollTarget();
    },

    scrollTarget() {
      const target = document.querySelector(".customer-detail-target");
      target?.scrollIntoView({
        behavior: "smooth",
      });
    },

    async fetchFinanceData() {
      const [res, ret] = await Promise.all([
        this.$http.post("home/finance/list", {
          params: {
            query: JSON.stringify({
              ...this.query,
              sort_key:this.sort_key
            }),
          },
        }),
        this.$http.post("home/finance/data", {
          params: {
            query: JSON.stringify(this.query),
          },
        }),
      ]);
      this.countData = ret.data[0];
      this.page.total = res.data.total;
      this.remainTotal = res.data.sub_remain;
      this.data = res.data.data;
    },
    changPage2(page = 1, pageSize = 10) {
      this.page.current = page;
      this.page.limit = pageSize;
      this.fetchFinanceData();
    },
    async fetchUser() {
      let where;
      if (this.user && this.user.role == 5) {
        where = {
          role: { $in: [1, 4, 5] },
          _id: { $in: [this.user._id, ...this.user.userInfo] },
        };
      } else {
        where = {
          role: { $in: [1, 4, 5] },
        };
      }
      const res = await this.$http.get(`users`, {
        params: {
          query: {
            limit: 0,
            where: where,
          },
        },
      });
      const users = res.data.data;
      // users.unshift({
      //   _id: "空",
      //   realname: "空",
      // });
      this.users = users;
    },
    async fetchCustomerData() {
      const { data } = await this.$http.get("customers/ranks/data", {
        params: {
          query: JSON.stringify({
            ...this.query,
            page: this.rankPage.page,
            limit: this.rankPage.limit,
          }),
        },
      });
      this.customerData = data.data;
      this.rankPage.total = data.total;
    },
  },
  created() {
    this.fetchFinanceData();
    // this.fetch();
    this.fetchGroups();
    this.fetchGroupPayback();
    this.fetchCustomers();
    this.fetchUser();
    this.fetchCustomerData();
    this.fetchOptions();
  },
};
</script>

<style>
.finace .el-col-4 {
  width: 19.9% !important;
}
.finace .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 72% !important;
}

.finace .group-table th {
  padding: 0.8rem 0rem;
}

.finace .group-table .el-button--mini {
  font-size: 15px;
}
</style>
